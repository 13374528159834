:root {
  --social-network-icon-color-main: #023d6b;
  --social-network-icon-color-hover: #023d6b;
  --social-network-background-main: transparent;
  --social-network-background-hover: transparent;
}

.social-network.item {
  background-color: var(--social-network-background-color-main);
}
.social-network.item:hover {
  background-color: var(--social-network-background-hover);
}
.social-network.item.animate:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.social-network.icon {
  color: var(--social-network-icon-color-main);
}
.social-network.icon:hover {
  color: var(--social-network-icon-color-hover);
}

.ui.container.social-networks.full, .ui.container.social-networks.center {
  text-align: center;
}
.ui.container.social-networks.left {
  text-align: left;
}
.ui.container.social-networks.right {
  text-align: right;
}
.ui.container.social-networks .item {
  padding: 0.1rem;
}

.block.follow_us.full, .block.follow_us.center {
  text-align: center;
}
.block.follow_us.left {
  text-align: left;
}
.block.follow_us.right {
  text-align: right;
}
.block.follow_us .follow_us.title {
  font-size: 1.7rem;
  font-weight: 500;
}
.block.follow_us .social-networks {
  padding-top: 0.5rem;
}

.block.gridBlock .block.follow_us.center {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
}