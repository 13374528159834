body #footer .footer {
  padding-right: 220px;
  padding-left: 220px;
  border-top: thick double var(--reverse-color);
  background: var(--main-color);
  color: var(--reverse-color);

  @media only screen and (max-width: $tablet-breakpoint) {
    padding-right: 30px;
    padding-left: 30px;
  }

  .content-container {
    display: flex;
    flex-direction: column;

    .address-contact-container {
      display: flex;
      justify-content: space-between;
      gap: 25px;

      @media only screen and (max-width: $large-monitor-breakpoint) {
        flex-flow: column;
        justify-content: flex-start;
      }

      p.headline {
        padding-bottom: 15px;
        border-bottom: 1px solid $white;
        margin-bottom: 18px;
        @include add(weight, bold);
        line-height: 36px;
      }

      p,
      address {
        text-align: left;
      }

      .socialNetworks {
        min-width: 20%;

        a.external.social-network.item {
          svg {
            fill: var(--reverse-color) !important;
          }
        }
      }
    }
  }
}
