body #main {
  > .breadcrumbs {
    padding: 10px 0;
    background-color: var(--breadcrumbs-background-color);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    .breadcrumb {
      max-width: var(--default-container-width);
      margin-right: 5px;
      margin-left: 5px;
      color: var(--breadcrumbs-text-color) !important;
      text-transform: uppercase;

      .section {
        color: var(--breadcrumbs-text-color) !important;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
      .divider::before {
        color: var(--breadcrumbs-text-color) !important;
        content: '▲';
        @include add(size, 2xs);
      }
    }
  }
}
