// Header wrapper
body header.header-wrapper {
  padding: 0 0;
  background-color: var(--main-color);
  // Logo wrapper
  .header {
    .tools-wrapper {
      display: none;
    }

    .logo-nav-wrapper {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .search-wrapper {
      flex: 0 1 0;
      // display: none;
      .search .search-bar {
        top: -155px;
      }
    }
    nav.navigation {
      a.item,
      button.item {
        color: var(--reverse-color);
        @include add(size, xs);
        text-transform: uppercase !important;

        &.active,
        &:hover {
          color: var(--reverse-color);

          &::before {
            bottom: -70px;
            border-bottom: 12px solid var(--reverse-color);
          }
        }
      }

      ul.desktop-menu .submenu-wrapper {
        margin-top: 58px;

        .submenu {
          border-top: 1px solid var(--breadcrumbs-background-color);
          background: var(--main-color);

          &.active {
            .close {
              background: var(--main-color);
              color: var(--reverse-color);
              svg path {
                fill: var(--reverse-color);
              }
            }
          }

          .submenu-inner {
            h2 {
              color: var(--reverse-color);
            }

            .submenu-header {
              h2 {
                margin-left: 0px;
                @include add(weight, bold);
              }
            }

            .subitem-wrapper {
              border-left: 1px solid var(--reverse-color);

              > a,
              li > a {
                color: var(--reverse-color);

                span {
                  &:hover {
                    border-bottom: 1px solid var(--reverse-color);
                  }
                  color: var(--reverse-color);
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobile-nav .hamburger:before,
.mobile-nav .hamburger::after,
.mobile-nav .hamburger-inner:after,
.mobile-nav .hamburger-inner::before {
  background-color: var(--breadcrumbs-background-color);
}
