body {
  h1.documentFirstHeading {
    color: var(--main-color-dark);
    @include add(size, 3xl);
    @include add(weight, bold);
  }

  h2.headline,
  .block h2.headline,
  .externalDataForm h2.headline,
  .externalDataResults h2.headline,
  .block.gridBlock h2.headline,
  .block.listing.grid .headline,
  .block.heading h2.heading,
  .block.search h2.heading,
  .block.heading h2.editable {
    padding-bottom: 10px;
    border-bottom: 3px solid var(--main-color-dark);
    margin-bottom: 40px;
    color: var(--main-color-dark);
    @include add(size, 2xl);
    @include add(weight, semi-bold);
  }
}

body.acc-cont-h h1.documentFirstHeading {
  color: var(--text-color);
}

body.acc-cont-h .block > h2.headline,
body.acc-cont-h h2.headline,
body.acc-cont-h .block.listing.grid .headline {
  border-bottom-color: var(--text-color);
  color: var(--text-color);
}
